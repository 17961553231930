import * as React from 'react';
import styled from 'styled-components';
import MagicAdd from '../assets/images/logos/magic-add-logo.png';
import IOP from '../assets/images/logos/iop.png';
import { LogoWrapper, Line } from '../containers/style';

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  text-align: center;
`;

const Logo = styled.img`
  margin: 5px;
  height: 25px;
`;

const FooterText = styled.p`
  font-size: 12px;
  margin: auto;
  width: 60%;
  @media only screen and (max-width: 400px) {
    font-size: 10px;
    width: 80%;
  }
`;

interface FooterProps {
  children: any;
}

const Footer = (props: FooterProps) => {
  return (
    <FooterWrapper>
      <Line />
      <FooterText>{props.children}</FooterText>
      <LogoWrapper>
        <Logo src={IOP} />
        <Logo src={MagicAdd} />
      </LogoWrapper>
    </FooterWrapper>
  )
}

export default Footer;
