import * as React from 'react';
import Konelogo from '../assets/images/logos/kone-logo.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 35px 0 30px 0;
    @media only screen and (max-width: 400px) {
      padding: 15px 0;
      height: 35px;
    }
`;

const Header = () => (
  <Wrapper>
    <img src={Konelogo} alt='KONE logo' />
  </Wrapper>
)

export default Header;
