import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import pin from '../assets/images/location-pin/location-pin.svg';
import shadow from '../assets/images/location-pin/pin-shadow.svg';

const rotateY = keyframes`
  to { transform: rotateY(360deg); }
`;

const PinWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 50px;
    margin-top: 20px;
    position: relative;
    @media only screen and (max-width: 400px) {
      height: 40px;
      margin-top: 10px;
    }
`;

const ImageTop = styled.img`
  animation: ${rotateY} 1s linear;
  left: ${props => 'calc((100% - ' + props.width + ')/2)'};
  position: absolute;
  z-index: 2;
  @media only screen and (max-width: 400px) {
    width: 26px;
  }
`;

const ImageBottom = styled.img`
  left: ${props => 'calc((100% - ' + props.width + ')/2)'};
  position: absolute;
  top: 46px;
  z-index: 1;
  @media only screen and (max-width: 400px) {
    top: 32px;
    width: 35px;
  }
`;

const Pin = () => {
  return (
    <PinWrapper>
      <ImageTop src={pin} alt='Location pin' width='38px'></ImageTop>
      <ImageBottom src={shadow} alt='' width='46px'></ImageBottom>
    </PinWrapper>
  )
}

export default Pin;
