import * as React from 'react';
import { PageWrapper } from './style';
import styled from 'styled-components';

const Text = styled.p`
    text-align: center;
`;

const ErrorPage = () => {
    return (
        <PageWrapper>
            <Text>Could not find the elevator information.</Text>
        </PageWrapper>
    )
}

export default ErrorPage;
