import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from '../components/Header';
import VideoCall from './VideoCall';
import FrontPage from './FrontPage';
import Survey from './Survey';
import { Elevator } from '../models/Elevator';
import ErrorPage from './ErrorPage';
import { initGTM, trackEvent } from '../utils/gtm';

interface AppState {
  campaign: string;
  elevator: Elevator;
  error: boolean;
  token: string;
}

class App extends React.Component<any, AppState> {

  constructor(props: any) {
    super(props);
    this.state = {
      campaign: '',
      elevator: {
        address: '',
        city: '',
        floor_number: '',
        postalcode: '',
        qr_code_location: '',
      },
      error: false,
      token: '',
    };
  }

  componentWillMount() {
    initGTM();
  }

  handleDataFetch = (newState: any) => {
    this.setState({ ...newState, error: false });
  }

  handleError = () => {
    this.setState({ error: true })
    trackEvent('Error', 'Unknown referral code', window.location.search);
  }

  render() {
    const { campaign, elevator, error, token } = this.state;
    return (
      <div className='App'>
        <Header />
        {error
          ?
          <ErrorPage />
          :
          <Router>
            <Switch>
              <Route
                path='/emergencycall'
                render={(props) => (
                  <VideoCall {...props} elevator={elevator} />
                )}
              />
              <Route
                path='/survey'
                render={(props) => (
                  <Survey {...props} campaign={campaign} elevator={elevator} token={token} />
                )}
              />
              <Route
                path='/'
                render={(props) => (
                  <FrontPage {...props} elevator={elevator} onDataFetch={this.handleDataFetch} onError={this.handleError} />
                )}
              />
            </Switch>
          </Router>
        }
      </div>
    );
  }
}

export default App;
