const prod = {
  analytics: {
    GA: 'UA-143252080-1'
  },
  elevator: {
    AUTH_TOKEN: 'Token token=5669158b0ce143a8834428e13dbda26b',
    BASE_URL: 'https://camp.magicadd.com',
    CAMPAIGN_CODE: 'plt'
  },
  iotcomms: {
    DESTINATION: 'magicaddagent@kone.stage.iotcomms.io',
    SIP_DOMAIN: 'kone.stage.iotcomms.io',
    SIP_PASSWORD: '@l<mME2qMY@@&if=',
    SIP_SERVER: 'sip.stage.iotcomms.io',
    SIP_USER: 'elevator1',
  },
  surveymonkey: {
    URL: 'https://www.surveymonkey.com/r/MHDKTX9'
  }
};

const dev = {
  analytics: {
    GA: null,
  },
  elevator: {
    AUTH_TOKEN: 'Token token=5669158b0ce143a8834428e13dbda26b',
    BASE_URL: 'https://camp.magicadd.com',
    CAMPAIGN_CODE: 'kon3'
  },
  iotcomms: {
    DESTINATION: 'magicaddagent@kone.test.iotcomms.io',
    SIP_DOMAIN: 'kone.test.iotcomms.io',
    SIP_PASSWORD: '<D=TQicv#E3xerNV',
    SIP_SERVER: 'sip.test.iotcomms.io',
    SIP_USER: 'elevator1',
  },
  surveymonkey: {
    URL: 'https://www.surveymonkey.com/r/MHDKTX9'
  }
};

export const config = process.env.NODE_ENV === `development` ? dev : prod;
