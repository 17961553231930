import ReactGA from 'react-ga';
import { config } from '../constants';

export const initGTM = () => {
  const trackingId = config.analytics.GA;
  if (trackingId) ReactGA.initialize(trackingId);
}

export const trackPage = (path: string) => {
  if (config.analytics.GA) ReactGA.pageview(path);
}

export const trackEvent = (category: string, action: string, label: string) => {
  if (config.analytics.GA) ReactGA.event({ category, action, label });
}
