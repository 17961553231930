import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Pin from '../components/Pin';
import { Elevator } from '../models/Elevator';
import { config } from '../constants';
import { trackPage } from '../utils/gtm';

const CTAButton = styled.div`
  color: #FFF;
  font-size: 30px;
  letter-spacing: 0.2px;
  line-height: 34px;
  padding: 35px 10px;
  text-decoration: none;

  @media only screen and (max-width: 400px) {
    font-size: 20px;
    line-height: 22px;
    padding: 15px 60px;
  }
`;

const CTAButtonRight = styled(CTAButton)`
  background-color: rgba(255, 255, 255, .2);
  border-radius: 100px 0px 0px 100px;
  margin-left: 50px;
  margin-bottom: 15px;
  padding-left: 100px;
  text-align: left;
  @media only screen and (max-width: 400px) {
    margin-bottom: 10px;
    padding-right: 30px;
    padding-left: 55px;
  }
  @media only screen and (min-width: 768px) {
    margin-left: 20%;
    padding-left: 120px;
  }
`;

const CTAButtonLeft = styled(CTAButton)`
  background-color: #003c88;
  border-radius: 0px 100px 100px 0px;
  margin-right: 50px;
  padding-right: 100px;
  text-align: right;
  @media only screen and (max-width: 400px) {
    padding-left: 30px;
    padding-right: 55px;
  }
  @media only screen and (min-width: 768px) {
    margin-right: 20%;
    padding-right: 120px;
  }
`;

const InfoLink = styled.a`
  font-size: 12px;
  text-decoration: underline;
`;

const LocationLabel = styled.p`
  font-size: 14px;
  margin-bottom: 7px;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (max-width: 400px) {
    margin: 7px 0 3px 0;
  }
`;

const LocationText = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  text-align: center;
  width: 60%;
`;

interface FrontPageProps extends RouteComponentProps {
  elevator: Elevator;
  onDataFetch: (values: object) => void;
  onError: () => void;
}

interface ElevatorInfo {
  key: string;
  value: string;
}

export default class FrontPage extends React.Component<FrontPageProps, any> {
  componentDidMount() {
    this.getElevatorInfo()
    trackPage(this.props.location.pathname + this.props.location.search);
  }

  getElevatorInfo() {
    const token = queryString.parse(this.props.location.search).referral;
    const campaign = config.elevator.CAMPAIGN_CODE;
    if (campaign && token) {
      const api = `${config.elevator.BASE_URL}/api/token?campaign_prefix=${campaign}&token=${token}`;
      axios.get(api, { headers: { 'Authorization': config.elevator.AUTH_TOKEN } })
        .then(response => {
          const data = response.data;
          const elevatorInfo = data.extra_info[0].content;
          let elevator: any = {}
          elevatorInfo.forEach((e: ElevatorInfo) => {
            const elevatorKey = e.key.toLowerCase();
            elevator[elevatorKey] = e.value
          });
          this.props.onDataFetch({ campaign, elevator, token })
        })
        .catch(error => {
          this.props.onError();
        })
    } else {
      this.props.onError();
    }
  }

  render() {
    const { address, postalcode, city, qr_code_location, floor_number } = this.props.elevator;
    return (
      <>
        <div>
          <Link to={{ pathname: '/survey', search: this.props.location.search }}>
            <CTAButtonRight>
              Click here & <br />give <b>feedback</b> <br />on your elevator <br />experience
            </CTAButtonRight>
          </Link>

          <Link to={{ pathname: '/emergencycall', search: this.props.location.search }}>
            <CTAButtonLeft>
              In case of<br /><b> emergency</b>, click<br /> here to start<br /> a video call to Kone
            </CTAButtonLeft>
          </Link>

          <Pin />
          <LocationLabel>
            Your elevator position is:
          </LocationLabel>
          <LocationText>
            {address ? `${address},` : ''} {postalcode} {city}
            {qr_code_location && floor_number ? `, ${qr_code_location} ${floor_number}` : ''}
          </LocationText>
        </div>

        <Footer>
          <InfoLink href='https://kone.com' target='_blank'>Information about KONE Digiboard People Flow information system</InfoLink>
        </Footer>
      </>
    )
  }
}
