import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Footer from '../components/Footer';
import { TopWrapper, TitleWrapper, PageTitle, Line } from './style';
import styled from 'styled-components';
import BackLink from '../components/BackLink';
import { Elevator } from '../models/Elevator';
import queryString from 'query-string';
import { config } from '../constants';
import { trackPage } from '../utils/gtm';

interface SurveyProps extends RouteComponentProps {
  elevator: Elevator;
  campaign: string;
  token: string;
}

const Iframe = styled.iframe`
  border: 0;
  display: block;
  height: 500px;
  margin: auto !important;
  position: relative;
  top: -80px;
  width: 90%;
`;

const IframeWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default class Survey extends React.Component<SurveyProps> {
  componentDidMount() {
    trackPage(this.props.location.pathname + this.props.location.search);
  }

  render() {
    const { campaign, token, elevator } = this.props;
    const { address, city, floor_number, postalcode, qr_code_location } = elevator;
    const params = queryString.stringify({
      eToken: token, campaign, address, city, floor_number, postalcode, qr_code_location
    });

    return (
      <>
        <TopWrapper>
          <BackLink search={this.props.location.search} />
          <TitleWrapper><PageTitle>Feedback</PageTitle></TitleWrapper>
          <Line />
        </TopWrapper>

        <IframeWrapper>
          <Iframe
            title='Survey'
            src={`${config.surveymonkey.URL}?${params}`}
          >
          </Iframe>
        </IframeWrapper>

        <Footer>
          Your feedback is anonymous. KONE will not share this information.
      </Footer>
      </>
    )
  }
}
