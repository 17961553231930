import * as React from 'react';
import LeftArrow from '../assets/images/left-arrow/main-arrow-left.svg';
import { ImageLink } from '../containers/style';

function BackLink(search: any) {
  return (
    <ImageLink to={{ pathname: '/', search: search.search }}>
      <img src={LeftArrow} alt='Arrow back' />
    </ImageLink>
  )
}

export default BackLink;
