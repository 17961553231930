import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import WebRTCClient from 'iotcomms-react-webrtc';
import Footer from '../components/Footer';
import { PageWrapper, LogoWrapper, Line, TopWrapper, TitleWrapper, PageTitle } from './style';
import styled from 'styled-components';
import BackLink from '../components/BackLink';
import './VideoCall.css';
import IotCommsLogo from '../assets/images/logos/iotcomms.png';
import Ring from '../assets/videos/animation.mp4';
import { Elevator } from '../models/Elevator';
import { config } from '../constants';
import { trackPage } from '../utils/gtm';

interface VideoCallProps extends RouteComponentProps {
  elevator: Elevator;
}

const VideoWrapper = styled.div`
  display: block;
  margin: auto;
  position: relative;
  @media only screen and (min-width: 769px) {
    width: 60%;
  }
`;

const SmallVideo = styled.video`
  bottom: 3px;
  position: absolute;
`;

export default class VideoCall extends React.Component<VideoCallProps> {
  componentDidMount() {
    trackPage(this.props.location.pathname + this.props.location.search);
  }

  render() {
    return (
      <>
        <TopWrapper>
          <BackLink search={this.props.location.search} />
          <TitleWrapper><PageTitle>Emergency call</PageTitle></TitleWrapper>
          <Line />
        </TopWrapper>

        <PageWrapper>
          <VideoWrapper>
            <SmallVideo width='25%' id='localVideo' autoPlay playsInline muted={true} />
            <video width='100%' id='remoteVideo' autoPlay playsInline />
          </VideoWrapper>

          <div className='video'>
            <WebRTCClient
              video={true}
              autoRegister={false}
              sipDomain={config.iotcomms.SIP_DOMAIN}
              sipServer={config.iotcomms.SIP_SERVER}
              sipPassword={config.iotcomms.SIP_PASSWORD}
              sipUser={config.iotcomms.SIP_USER}
              destination={config.iotcomms.DESTINATION}
              metaData={this.props.elevator}
              ringbackVideoUrl={Ring}
            />
          </div>
        </PageWrapper>

        <LogoWrapper>
          <img src={IotCommsLogo} alt='Powered by iotcomms.io' width={140} height={52} />
        </LogoWrapper>

        <Footer>
          Your feedback is anonymous. KONE will not share this information.
        </Footer>
      </>
    )
  }
}
