import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageWrapper = styled.div`
  justify-content: center;
  padding: 10px 20px;
  @media only screen and (max-width: 400px) {
    padding: 5px 15px;
  }
`;

export const TopWrapper = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 400px) {
    margin-top: 0;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 85px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
`;

export const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0;
  margin: 5px 0;
  text-align: center;
  text-transform: uppercase;
  @media only screen and (max-width: 400px) {
    font-size: 16px;
  }
`;

export const ImageLink = styled(Link)`
  float: left;
  display: block;
  padding: 7px 30px 10px 45px;
  @media only screen and (max-width: 400px) {
    padding: 5px 35px;
  }
`;

export const Line = styled.hr`
  border-top: 1px solid #326fbc;
  border-bottom: none;
  margin: 15px 0;
  @media only screen and (max-width: 400px) {
    margin: 10px 0;
  }
`;
